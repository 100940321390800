import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'

import AniLink from "gatsby-plugin-transition-link/AniLink";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Subscribe = () => {
	const [listFields, setListFields] = useState({
		EMAIL: '',
		FNAME: '',
		LNAME: '',
		MMERGE3: '',
		MMERGE5: ''
	})

	const handleChange = e => {

        setListFields((prevListFields) => {
			return ({
				...prevListFields,
				[`${e.target.name}`]: e.target.value
			})
        })
    }

	const handleSubmit = e => {
		e.preventDefault();

		// console.log(listFields);

		document.querySelector('#response .loadingSpinner').classList.add('show');
		addToMailchimp(listFields.EMAIL, listFields).then(({ msg }) => {
				document.querySelector('#response p').innerHTML = msg;
				document.querySelector('#response .loadingSpinner').classList.remove('show');
		}).catch(err => {
			document.querySelector('#response .loadingSpinner').classList.remove('show');
			// console.log('err', err)
			alert(err)
		})
	}

	const toggleSubmit = (e) => {
		if (e.target.checked){
			document.getElementById('submitButton').classList.remove('hidden');
		} else {
			document.getElementById('submitButton').classList.add('hidden');
		}
	}

	return (
		<section className="content" id="subscribe">
			<h1>Register for our newsletter</h1>
			<h2>Don’t miss out on Travel Retail’s latest consumer insights</h2>
			<form onSubmit={handleSubmit} id="subscribeForm">
				<div>
					<input type="text" name="FNAME" id="FNAME" placeholder="First name" onChange={handleChange}/>
				</div>
				<div>
					<input type="text" name="LNAME" id="LNAME" placeholder="Last name" onChange={handleChange}/>
				</div>
				<div>
					<input type="email" name="EMAIL" id="EMAIL" placeholder="E-mail address" onChange={handleChange}/>
				</div>
				<div>
					<input type="text" name="MMERGE3" id="MMERGE3" placeholder="Company" onChange={handleChange}/>
				</div>
				<div>
					<input type="text" name="MMERGE5" id="MMERGE5" placeholder="Name" onChange={handleChange}/>
				</div>
				<div id="privacyAgreeWrapper">
					<input type="checkbox" name="" id="privacyAgree" onChange={toggleSubmit}/>
					<label htmlFor="privacyAgree">
						<FontAwesomeIcon icon={faSquare} id="noChecked"/>
						<FontAwesomeIcon icon={faCheckSquare} id="checked"/>
						I agree to the&nbsp;
						<AniLink
							to="/privacy-policy-newsletters"
							className="nav-link-text"
							cover bg="#EE82BF"
							direction="right"
							duration={0.8}
						>
							privacy policy
						</AniLink>.
					</label>
				</div>
				<p>
					<button type="submit" id="submitButton" className="button-general small hidden">Subscribe</button>
				</p>
			</form>
			<div id="response">
				<FontAwesomeIcon icon={faSpinner} className="loadingSpinner"/>
				<p></p>
			</div>
		</section>
	);
};

export default Subscribe;